import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static values = {
    key: String,
    val: String,
    urlKey: String
  }

  connect() {
    if (this.hasUrlKeyValue && this.getFromStorage()) {
      // console.log(this.keyValue)
      // console.log(this.urlKeyValue)
      // console.log(this.getFromStorage())
      const path = this.element.href
      this.element.href = `${path}&${this.urlKeyValue}=${this.getFromStorage()}`
    }
  }

  store(event) {
    if (this.hasKeyValue && this.hasValValue) {
      localStorage.setItem(this.keyValue, this.valValue)
      // console.log(`stored: ${this.keyValue}: ${this.valValue}`)
    }
  }

  getFromStorage() {
    return localStorage.getItem(this.keyValue)
  }

}
